import React from "react";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import { InView } from "utils/animation";
import Button from "elements/Button";
import ProgramCard from "./ProgramCard";

import "./Program.scss";

const Program = ({ grants }) => {
  return (
    <div className="program">
      <InView className="program__content-wrapper">
        <div className="program__content a-fade-up">
          <h2 className="program__title t-xl">Grants Program</h2>
          <p className="program__description t-sm">
            Our Grants Program offers recurring grants for public applications, which are tracked transparently on{" "}
            <Link className="t-light-gray" to="https://github.com/w3f/Grants-Program">
              Github
            </Link>{" "}
            and disbursed in cryptocurrencies. Exceptions to these requirements and higher funding are also possible.
          </p>
          <Button to="https://github.com/w3f/Grants-Program#web3-foundation-grants-program" className="program__button">
            Apply
          </Button>
        </div>
      </InView>
      <div className="program__cards">
        {grants.map(({ html, frontmatter }, index) => {
          return (
            <InView key={index} className="program__card">
              <ProgramCard className="a-fade-up-from-bottom u-shadow" {...frontmatter} body={html} />
            </InView>
          );
        })}
      </div>
    </div>
  );
};

export default Program;
