import React from "react";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import clsx from "clsx";

import "./Button.scss";

const Button = ({ children, to, onClick, gray = false }) => {
  const classes = clsx("button u-hover-grow", { "button--gray": gray });

  if (onClick) {
    return (
      <button className={classes} onClick={onClick}>
        {children}
      </button>
    );
  }

  return (
    <Link className={classes} to={to}>
      {children}
    </Link>
  );
};

export default Button;
