import React from "react";

import "./Aside.scss";

const Aside = ({ title, content }) => {
  return (
    <aside className="aside">
      <h3 className="aside__title t-bold">{title}</h3>
      <div className="t-text t-sm aside__content">{content}</div>
    </aside>
  );
};

export default Aside;
