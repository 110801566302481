import React, { useMemo } from "react";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import { graphql } from "gatsby";
import { sortCollectionItems } from "utils/sort-collection-items";
import Layout from "components/site/Layout";
import Hero from "components/Hero";
import Container from "layouts/Container";
import Recipients from "../components/Recipients";
import Program from "../components/Program";

const GrantsPage = ({ data: { page, allGrants, footerImage } }) => {
  const { t } = useTranslation();
  const { grants, seo } = page.frontmatter;
  const meta = seo ? { ...seo } : {}; // In case the seo object is null

  const sortedItems = useMemo(() => sortCollectionItems(allGrants.nodes, grants), [allGrants, grants]);

  return (
    <Layout footerImage={footerImage}>
      <SEO {...meta} />

      <Container className="container--t-margin-sm container--b-margin-md" noOverflow={false}>
        <Hero
          title={t("Grants")}
          content={
            <p className="t3">
              In support of our mission, the Web3 Foundation grants program funds software development and research in
              the field of decentralized software protocols.
            </p>
          }
          delayedAside={false}
          asideTitle="Philosophy"
          asideContent={
            <>
              <p>
                Our grants program&apos;s aim is to steward the Web 3.0 ecosystem and carefully manage Web3 Foundation
                resources. We provide grants that we believe will return the highest impact on the Web 3.0 ecosystem
                over the long term.
              </p>
              <p>
                Web3 Foundation awards grants for specific projects and pieces of development work. Grant recipients
                should not be considered partners of Polkadot or Web3 Foundation.
              </p>
            </>
          }
        />
      </Container>
      <Container>
        <Recipients />
      </Container>
      <Container className="container--t-margin-sm" withBackground>
        <Program grants={sortedItems} />
      </Container>
    </Layout>
  );
};

export default GrantsPage;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//pages/grants.md/" }) {
      html
      frontmatter {
        grants
        seo {
          ...Seo
        }
      }
      fields {
        langKey
        slug
      }
    }
    allGrants: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//grants//" } }
    ) {
      nodes {
        html
        frontmatter {
          id
          title
          subtitle
        }
      }
    }
    footerImage: file(name: { eq: "grants-large" }) {
      ...BgImage
    }
  }
`;
