import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

import "./Counter.scss";

const Counter = ({ className, countTo, title }) => {
  const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: true, delay: 200 });
  const [count, setCount] = useState(0);

  const doIncrement = () => {
    if (inView && count < countTo) {
      const timer = setTimeout(() => {
        setCount(count + 1);
      }, Math.round(1000 / countTo));
      return () => clearTimeout(timer);
    }
  };

  useEffect(doIncrement, [count, countTo, inView]);

  return (
    <aside ref={ref} className={clsx("counter a-init", className, { "a-in-view": inView })}>
      <p className="counter__value t-bold a-fade-up">{count}+</p>
      <p className="counter__title t-lg a-fade-up">{title}</p>
    </aside>
  );
};

export default Counter;
