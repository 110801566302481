export const sortCollectionItems = (items, sortArray) => {
  if (!sortArray) return [];

  let sortedItems = Array(sortArray.length);

  for (let i = 0; i < sortArray.length; i++) {
    for (let j = 0; j < items.length; j++) {
      // eslint-disable-next-line security/detect-object-injection
      if (sortArray[i] === items[j].frontmatter.id) {
        // eslint-disable-next-line security/detect-object-injection
        sortedItems[i] = items[j];
        break;
      }
    }
  }

  return sortedItems;
};
