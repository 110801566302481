import React from "react";
import { InView, staggerStyle } from "utils/animation";
import clsx from "clsx";
import Aside from "elements/Aside";
import ArrowLink from "elements/ArrowLink";

import "./Hero.scss";

const Hero = ({ title, ctaLink, content, asideTitle, asideContent, delayedAside = true }) => {
  return (
    <InView className={clsx("hero")}>
      <div className="hero-body a-fade-up-and-in">
        <h1 className={clsx("hero-body__title t-title-1 a-fade-up-from-bottom")}>{title}</h1>
        <div className="hero-body__content t-xl">{content}</div>
        {ctaLink && (
          <ArrowLink className="hero-body__cta-link" to={ctaLink.url}>
            {ctaLink.title}
          </ArrowLink>
        )}
      </div>
      {asideContent && asideTitle && (
        <div className={clsx("hero__aside a-fade-up-from-bottom")} style={staggerStyle(delayedAside ? 4 : 0)}>
          <Aside title={asideTitle} content={asideContent} />
        </div>
      )}
    </InView>
  );
};

export default Hero;
