import React from "react";
import clsx from "clsx";

import "./ProgramCard.scss";

const ProgramCard = ({ className, title, subtitle, body }) => {
  return (
    <div className={clsx("program-card", className)}>
      <h3 className="t-title-2">{title}</h3>
      <p className="program-card__subtitle t-lg">{subtitle}</p>
      <p className="program-card__body t-sm t-text" dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  );
};

export default ProgramCard;
