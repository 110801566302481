import React from "react";
import clsx from "clsx";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import ArrowRight from "/src/images/svgs/arrow-right.svg";

import "./ArrowLink.scss";

const ArrowLink = ({ to, children, reversed, stretched = false, className, ...props }) => {
  return (
    <Link
      className={clsx("arrow-link t-sm", className, reversed ? "arrow-link--reversed" : "arrow-link--default", {
        "arrow-link--stretched": stretched,
      })}
      to={to}
      {...props}
    >
      {children} <ArrowRight className="arrow-link__icon" />
    </Link>
  );
};

export default ArrowLink;
