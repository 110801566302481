import React from "react";
import ArrowLink from "elements/ArrowLink";
import Counter from "../Counter";
import { InView, staggerStyle } from "utils/animation";

import "./Recipients.scss";

const Recipients = () => {
  return (
    <div className="recipients">
      <InView className="recipients__col recipients__col-1">
        <h2 className="recipients__title t-title-2 a-fade-up">Grants recipients</h2>
        <p className="recipients__description a-fade-up" style={staggerStyle(2)}>
          Development projects funded through our grants program are broad in scope and include low-level infrastructure
          development such as alternative client implementations; ecosystem components such as wallets; and software
          designed to enable direct use cases such as identity and supply-chain modules.
        </p>
        <ArrowLink
          className="recipients__link a-fade-up"
          style={staggerStyle(4)}
          to="https://github.com/w3f/Grants-Program/blob/master/applications/index.md"
        >
          See all recipients
        </ArrowLink>
      </InView>
      <Counter className="recipients__col recipients__col-2" countTo={500} title="projects funded" />
      <Counter className="recipients__col recipients__col-3" countTo={50} title="in countries" />
    </div>
  );
};

export default Recipients;
